
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useAttrs,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiOrderTracking, ApiSalesOrders } from "@/core/api";
import FsLightbox from "fslightbox-vue/v3";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import {
  commonBackToList,
  commonCountryFlagPath,
  commonExportFile,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  getCustomerClearanceModeMap,
  getPartialShipmentMap,
  getSalesOrderPaymentStatus,
  getSalesOrderRFMMap,
  getSalesOrderStatus,
  getSalesOrderStatusMap,
  getSalesOrderTypeOptions,
  getSettlementStatusMap,
} from "@/core/directive/function/salesOrder";
import {
  CommonDateType,
  CommonRequiredSwtich,
  CommonSwtich,
  NumberOrString,
} from "@/core/directive/type/common";
import {
  SalesOrderOverview,
  salesOrderOverviewFormData,
  salesOrderOverviewInvoiceData,
  SalesOrderOverviewInvoiceInvoices,
} from "@/core/directive/interface/salesOrder";
import {
  SalesOrderNewStatus,
  SalesOrderSettlementStatus,
} from "@/core/directive/type/salesOrder";
import JwtService from "@/core/services/JwtService";
import {
  getServiceProviderMap,
  getShipmentStatusMap,
} from "@/core/directive/function/shipment";
import {
  OrderTrackingOrderProgression,
  OrderTrackingOrderProgressionItem,
  OrderTrackingShipmentTracking,
} from "@/core/directive/interface/orderTracking";
import { OrderTrackingOrderProgressionKey } from "@/core/directive/type/orderTracking";
import { previewOrderImages } from "@/core/directive/function/order";
import { ProductItemImage } from "@/core/directive/interface/order";
import {
  getOrderTrackingOrderProgressionKeyMap,
  getOrderTrackingShipmentTrackingKeyMap,
} from "@/core/directive/function/orderTracking";
import { isAuditView } from "@/core/directive/function/order";
import CommonProduct from "@/components/table/CommonProduct.vue";

export default defineComponent({
  name: "sales-order-order-overview-overview",
  components: { FsLightbox, CommonProduct },
  setup() {
    const { t } = useI18n();
    const attrs = useAttrs(); // 接收父路由参数
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const disabledExportOrderNotice = ref<boolean>(false);
    const formData = ref(Object.assign({}, salesOrderOverviewFormData));
    const orderTrackingOrderProgressionData = ref<
      Array<OrderTrackingOrderProgression>
    >([]);
    const orderTrackingShipmentTracking = ref<
      Array<OrderTrackingShipmentTracking>
    >([]);

    const orderProgressionShowAll = ref(false);

    const options = ref({
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      partial_shipment: getPartialShipmentMap(t),
      shipmentTable: [],
      shipmentLoading: false,
      shipmentShowDetails: false,
      shipmentScheduleIsVirtual: false,
      scheduleLoading: true,
      invoiceLoading: true,
      invoiceData: Object.assign({}, salesOrderOverviewInvoiceData),
      orderNumberArr: [
        "",
        t("salesOrder.orderNumber"),
        t("salesOrder.wholesaleOrderNumber"),
        t("salesOrder.depositOrderNumber"),
        t("salesOrder.serviceOrderNumber"),
        t("salesOrder.serviceOrderNumber"),
      ],
      orderNumberIndex: 0,
      salesOrderNewStatus: SalesOrderNewStatus,
    });

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
        const data = attrs.detailData as SalesOrderOverview;
        formData.value = data;
        // 服务订单去查询账单信息是否有差额
        if (data.sales_order.store_id === 16) {
          getSalesOrderInvoiceBy(data.sales_order_identifier.order_number);
        }
      }
    );

    const DocumentsTableData = ref([]);

    const getOrderBatchTracking = async () => {
      options.value.shipmentLoading = true;
      const { data } = await ApiOrderTracking.getOrderBatchTracking({
        order_id: route.params.id,
      });
      options.value.shipmentLoading = false;
      if (data.code == 0) {
        let items: OrderTrackingShipmentTracking[] = Object.values(data.data);
        items.forEach((item) => {
          item.sku_list = Object.values(item.sku_list);
        });
        options.value.shipmentScheduleIsVirtual = Object.keys(data.data).find(
          (item) => item !== "Virtual"
        )
          ? false
          : true;
        orderTrackingShipmentTracking.value = items;
      }
    };

    const getOrderProgression = async () => {
      options.value.scheduleLoading = true;
      const { data } = await ApiOrderTracking.getOrderProgression({
        order_id: route.params.id,
      });
      options.value.scheduleLoading = false;
      if (data.code === 0) {
        let items: OrderTrackingOrderProgression[] = data.data;
        items.forEach((item) => {
          item.sku_list = Object.values(item.sku_list);
        });
        orderTrackingOrderProgressionData.value = items;
      }
    };

    const getSalesOrderInvoiceBy = async (order_number) => {
      options.value.invoiceLoading = true;
      const { data } = await ApiSalesOrders.getSalesOrderInvoiceBy({
        order_number: order_number,
      });
      options.value.invoiceLoading = false;
      if (data.code === 0) {
        options.value.invoiceData = data.data;
        // if (data.data.adjusted === 1) {
        //   getSalesOrderInvoiceItems(data.data.id);
        // }
      }
    };

    const getSalesOrderInvoiceItems = async (id) => {
      options.value.invoiceLoading = true;
      const { data } = await ApiSalesOrders.getSalesOrderInvoiceItems({
        id: id,
      });
      options.value.invoiceLoading = false;
      if (data.code === 0) {
        options.value.invoiceData = data.data;
      }
    };

    const changeOrderProgressionAll = () => {
      orderProgressionShowAll.value = !orderProgressionShowAll.value;
    };

    const getOrderProgressionFilter = computed(() => {
      let arr: OrderTrackingOrderProgression[] = [];
      if (orderProgressionShowAll.value) {
        arr = orderTrackingOrderProgressionData.value;
      } else {
        if (orderTrackingOrderProgressionData.value.length > 0) {
          arr.push(orderTrackingOrderProgressionData.value[0]);
          const currentIndex =
            orderTrackingOrderProgressionData.value.findIndex(
              (item) => item.crossed === 0
            );
          if (
            currentIndex > 0 &&
            currentIndex != orderTrackingOrderProgressionData.value.length - 1
          ) {
            arr.push(orderTrackingOrderProgressionData.value[currentIndex]);
          }
          arr.push(
            orderTrackingOrderProgressionData.value[
              orderTrackingOrderProgressionData.value.length - 1
            ]
          );
        }
      }
      return arr;
    });

    const getOrderProgressionCurrentStatus = computed(() => {
      return (index: number) => {
        let flag = false;
        const lastDoneIndex = _.findLastIndex(
          getOrderProgressionFilter.value,
          (item) => {
            return item.crossed === 1;
          }
        );
        if (lastDoneIndex + 1 === index) {
          flag = true;
        }
        return flag;
      };
    });

    const getVatArr = computed(() => {
      let vatArr: any[] = [],
        vatPercent: string[] = [];
      formData.value.ordered_items.map((item) => {
        if (vatPercent.indexOf(item.tax_percent) > -1) {
          vatArr[vatPercent.indexOf(item.tax_percent)].base_tax_amount +=
            Number(item.base_tax_amount);
        } else {
          vatPercent.push(item.tax_percent);
          vatArr.push({
            tax_percent: item.tax_percent,
            base_tax_amount: Number(item.base_tax_amount),
          });
        }
      });
      return vatArr;
    });

    const getInvoiceVatArr = computed(() => {
      return (item: SalesOrderOverviewInvoiceInvoices) => {
        let vatArr: any[] = [];
        Object.keys(item.precent).forEach((key) => {
          if (Number(key) > 0) {
            vatArr.push({
              tax_percent: key,
              base_tax_amount: item.precent[key],
            });
          }
        });
        return vatArr;
      };
    });

    const formatMbFulfillmentType = computed(() => {
      return (value: string, isFlag = true) => {
        return isFlag ? value : value.substring(0, value.indexOf("("));
      };
    });

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const customFormatDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
      return formatDate(date, format);
    };

    const backToList = () => {
      commonBackToList(router, "/sales-order/order-overview");
    };

    const getOrderType = computed(() => {
      return (status: NumberOrString) => {
        return getSalesOrderTypeOptions(Number(status), t);
      };
    });

    const orderStatus = computed(() => {
      return (status: string) => {
        return getSalesOrderStatus(status, t);
      };
    });

    const paymentStatus = computed(() => {
      return (status: string) => {
        return getSalesOrderPaymentStatus(status, t);
      };
    });

    const orderNewStatus = computed(() => {
      return (status: number) => {
        return getSalesOrderStatusMap(status, t)?.color;
      };
    });

    const settlementStatus = computed(() => {
      return (status: number) => {
        return getSettlementStatusMap(status, t)?.color;
      };
    });

    const getOrderProgressionTracking = computed(() => {
      return (item: OrderTrackingOrderProgressionItem[]) => {
        let arr: string[] = [],
          items: OrderTrackingOrderProgressionItem[] = [];
        item.map((list) => {
          if (list.completion.length === 5) {
            let tracking_no: string = list.completion[3] as string;
            let service_provider: string = list.completion[4] as string;
            let str = tracking_no + service_provider;
            if (arr.indexOf(str) === -1) {
              arr.push(str);
              items.push(list);
            }
          }
        });
        return items;
      };
    });

    const getName = computed(() => {
      return (row) => {
        return JwtService.getLang() === "zh_CN"
          ? row.sku_name_cn
          : row.sku_name_en;
      };
    });

    const changeShipmentShow = () => {
      options.value.shipmentShowDetails = !options.value.shipmentShowDetails;
    };

    const fileExportOrderNotice = async () => {
      if (disabledExportOrderNotice.value) true;
      disabledExportOrderNotice.value = true;
      ApiSalesOrders.exportOrderNotice({
        order_id: route.params.id,
      })
        .then((data) => {
          disabledExportOrderNotice.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportOrderNotice.value = false;
          console.log(error);
        });
    };

    const getFormInfo = () => {
      // loading.value = true;
      Promise.all([getOrderProgression(), getOrderBatchTracking()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const isEmptyString = (str) => {
      return !str || str.trim().length === 0;
    };

    const isDoubleSale = computed(() => {
      return route.path.includes("double-sale-order");
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        loading.value = false;
        const data = attrs.detailData as SalesOrderOverview;
        formData.value = data;
        // 服务订单去查询账单信息是否有差额
        if (data.sales_order.store_id === 16) {
          getSalesOrderInvoiceBy(data.sales_order_identifier.order_number);
        }
      }
      getFormInfo();
    });

    return {
      t,
      commonCountryFlagPath,
      formatDate,
      formatDateTime,
      SalesOrderSettlementStatus,
      CommonSwtich,
      CommonRequiredSwtich,
      OrderTrackingOrderProgressionKey,
      getOrderTrackingOrderProgressionKeyMap,
      getOrderTrackingShipmentTrackingKeyMap,
      getServiceProviderMap,
      getShipmentStatusMap,
      getSalesOrderRFMMap,
      getCustomerClearanceModeMap,
      loading,
      formData,
      orderTrackingOrderProgressionData,
      orderTrackingShipmentTracking,
      options,
      changeOrderProgressionAll,
      getOrderProgressionFilter,
      getOrderProgressionCurrentStatus,
      getVatArr,
      getInvoiceVatArr,
      formatMbFulfillmentType,
      previewImages,
      backToList,
      DocumentsTableData,
      getOrderType,
      orderStatus,
      orderNewStatus,
      paymentStatus,
      settlementStatus,
      getOrderProgressionTracking,
      getName,
      disabledExportOrderNotice,
      changeShipmentShow,
      fileExportOrderNotice,
      customFormatDate,
      isEmptyString,
      isDoubleSale,
      isAuditView,
    };
  },
});
